<template>
    <DialogClose v-bind="props">
        <slot>
            <i aria-hidden class="text-xl fass fa-xmark"></i>
            <span class="sr-only">Close</span>
        </slot>
    </DialogClose>
</template>
<script setup lang="ts">
import { DialogClose, type DialogCloseProps } from "radix-vue";

const props = defineProps<DialogCloseProps>();
</script>
